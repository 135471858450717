!(function (n, e, r, t, i, o, a, c, s) {
  for (var u = s, f = 0; f < document.scripts.length; f++) {
    if (document.scripts[f].src.indexOf(o) > -1) {
      u && document.scripts[f].getAttribute('data-lazy') === 'no' && (u = !1);
      break;
    }
  }
  const p = [];
  function l(n) {
    return 'e' in n;
  }
  function d(n) {
    return 'p' in n;
  }
  function _(n) {
    return 'f' in n;
  }
  const v = [];
  function y(n) {
    u &&
      (l(n) ||
        d(n) ||
        (_(n) && n.f.indexOf('capture') > -1) ||
        (_(n) && n.f.indexOf('showReportDialog') > -1)) &&
      m(),
      v.push(n);
  }
  function g() {
    y({ e: [].slice.call(arguments) });
  }
  function h(n) {
    y({ p: n });
  }
  function E() {
    try {
      n.SENTRY_SDK_SOURCE = 'loader';
      const e = n[i],
        o = e.init;
      (e.init = function (i) {
        n.removeEventListener(r, g), n.removeEventListener(t, h);
        const a = c;
        for (const s in i) {
          Object.prototype.hasOwnProperty.call(i, s) && (a[s] = i[s]);
        }
        !(function (n, e) {
          const r = n.integrations || [];
          if (!Array.isArray(r)) {
            return;
          }
          const t = r.map(function (n) {
            return n.name;
          });
          n.tracesSampleRate &&
            t.indexOf('BrowserTracing') === -1 &&
            (e.browserTracingIntegration
              ? r.push(e.browserTracingIntegration({ enableInp: !0 }))
              : e.BrowserTracing && r.push(new e.BrowserTracing()));
          (n.replaysSessionSampleRate || n.replaysOnErrorSampleRate) &&
            t.indexOf('Replay') === -1 &&
            (e.replayIntegration
              ? r.push(e.replayIntegration())
              : e.Replay && r.push(new e.Replay()));
          n.integrations = r;
        })(a, e),
          o(a);
      }),
        setTimeout(function () {
          return (function (e) {
            try {
              typeof n.sentryOnLoad === 'function' && (n.sentryOnLoad(), (n.sentryOnLoad = void 0));
              for (var r = 0; r < p.length; r++) {
                typeof p[r] === 'function' && p[r]();
              }
              p.splice(0);
              for (r = 0; r < v.length; r++) {
                _((o = v[r])) && o.f === 'init' && e.init.apply(e, o.a);
              }
              L() || e.init();
              const t = n.onerror,
                i = n.onunhandledrejection;
              for (r = 0; r < v.length; r++) {
                var o;
                if (_((o = v[r]))) {
                  if (o.f === 'init') {
                    continue;
                  }
                  e[o.f].apply(e, o.a);
                } else {
                  l(o) && t ? t.apply(n, o.e) : d(o) && i && i.apply(n, [o.p]);
                }
              }
            } catch (n) {
              console.error(n);
            }
          })(e);
        });
    } catch (n) {
      console.error(n);
    }
  }
  let O = !1;
  function m() {
    if (!O) {
      O = !0;
      const n = e.scripts[0],
        r = e.createElement('script');
      (r.src = a),
        (r.crossOrigin = 'anonymous'),
        r.addEventListener('load', E, { once: !0, passive: !0 }),
        n.parentNode.insertBefore(r, n);
    }
  }
  function L() {
    const e = n.__SENTRY__;
    return !(void 0 === e || !e.hub || !e.hub.getClient());
  }
  (n[i] = n[i] || {}),
    (n[i].onLoad = function (n) {
      L() ? n() : p.push(n);
    }),
    (n[i].forceLoad = function () {
      setTimeout(function () {
        m();
      });
    }),
    [
      'init',
      'addBreadcrumb',
      'captureMessage',
      'captureException',
      'captureEvent',
      'configureScope',
      'withScope',
      'showReportDialog',
    ].forEach(function (e) {
      n[i][e] = function () {
        y({ f: e, a: arguments });
      };
    }),
    n.addEventListener(r, g),
    n.addEventListener(t, h),
    u ||
      setTimeout(function () {
        m();
      });
})(
  window,
  document,
  'error',
  'unhandledrejection',
  'Sentry',
  '6ca3962d230b8c207a1d6918ccd12c15',
  'https://browser.sentry-cdn.com/7.112.2/bundle.tracing.replay.min.js',
  {
    dsn: 'https://6ca3962d230b8c207a1d6918ccd12c15@o31114.ingest.us.sentry.io/4507170618933248',
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  },
  false
);
